














































import { Component, Vue, Watch, Prop } from "vue-property-decorator"

@Component({
	name: "Default"
})
export default class Default extends Vue {
	@Prop(Object) goodsListItemData!: Object
	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	// created() {}

	// mounted() {}
}
